@import "variables";
// @import "helpers";

.news {

	h1 {
		color: #000;
		text-shadow: none;
		font-size: 2.3em;
	}


	.el-carousel {
		box-shadow: $box-shadow;
	}

	img {
		width: 90%;
		box-shadow: $box-shadow;
		margin: 20px auto;
		display: block;

	}
	p {
		
		padding: 10px 10px;
		font-family: $sfnst;
		line-height: 1.4em;
		font-size: 1.3em;
		margin-top: 10px;

	}
	
	a.queres-img-link img {
		box-shadow: none;
		max-width: 320px;
		margin: 0 auto;
	}



}