/* Styles for App */
@import "variables";
@import "helpers";

html {
    // background: #006D00 url(../img/bg/pasto_baja.jpg);

}
body {
    font-size: 13px;
    /* TRANSITION */
    -webkit-transition: background 1s;
    -moz-transition: background 1s;
    -o-transition: background 1s;
    transition: background 1s;
    // padding-bottom: 50px;
    background: #fff;
    // position: fixed;
    // top: 0;
    // left: 0;

    // width: 100vw;
    // height: calc(100vh - 100px);
    padding-top: 100px;
    overflow: auto;
    &.field_bg {

        background: #006D00 url(../img/bg/pasto_baja.jpg);
        background-repeat: repeat;

        header {
            background: url(../img/bg/pasto_baja.jpg) center center;
        // background: linear-gradient(to bottom, rgba(255,255,255,0) 20%,
              // rgba(255,255,255,1)), url(https://www.w3schools.com/w3css/img_lights.jpg);
        // background:url(../img/bg/pasto_baja.jpg) repeat-x 0 100%, -webkit-gradient(linear, 0% 0%, 0% 100%, from(#fff), to(#C4BCB1))
        }
    }

    &.field_bg:after{
        content:"";
        position:fixed; /* stretch a fixed position to the whole screen */
        top:0;
        height:100vh; /* fix for mobile browser address bar appearing disappearing */
        left:0;
        right:0;
        z-index:-1; /* needed to keep in the background */
        background: url(../img/bg/pasto_baja.jpg) center center;
        background-repeat: repeat;

        // -webkit-background-size: cover;
        // -moz-background-size: cover;
        // -o-background-size: cover;
        // background-size: cover;
    }
}


/* Layout app  */
.container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 100px);
    // height: calc( 100vh - 18    8px);
    overflow-y: auto;
    padding-bottom: 60px;
}

header {
    // align-self:flex-start;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    text-align: center;
    background-color: #fff;

    padding-top:15px;
    // position: relative;
    padding-bottom: 20px;

    &, * {

        transition: all 0.7s;
    }

}

main {
    flex: 1;
    // padding: 0 20px;
    // padding-bottom: 100px;
    // margin-top: 150px;

    .main-wrapper {
        max-width: 600px;
        margin: 0 auto 20px;
        padding: 0 20px;
    }
}

.copyright {
    // position: absolute;
    // bottom:30px;
    text-align: center;
    color: #000;
    body.field_bg & {
        color: #fff;
    }
}

ul.social {
    text-align: center;

    li {
        display: inline-block;

        a {
            $diametro: 30px;
            display: inline-block;
            width: $diametro;
            height: $diametro;
            background-size: cover;
            border-radius: 50%;
            margin: 0 10px;
            overflow: hidden;
            text-indent: -5000px;

            &.facebook {
                background-image: url(../img/bg/logo_facebook.png);
            }
            &.instagram {
                background-image: url(../img/bg/logo_instagram.png);
            }
            &.youtube {
                background-image: url(../img/bg/logo_youtube.png);
            }
        }


    }
}

footer {
    width: 100%;
    // background: rgba(0,0,0, 0.4);
    background: #fff;
    body.field_bg &{
        background: url(../img/bg/pasto_baja.jpg) center center;
    }
    color: #fff;
    font-family: $sfnst;
    line-height: 1.5;
    font-size: 11px;

    &:after{
        content: "";
        position: absolute;
        top: 0;left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0, 0.4);
        z-index: -1;

    }
    padding: 3px 0;
    margin-top: 20px;
    position: fixed;
    bottom: 0;
    z-index: 1;

    .logo-sponsor {
    	text-align: center;
    	img {height: 40px;}
    }
}

/* End Layout app  */


.logo-queres-jugar {
    text-align: center;
    position: relative;
    display: inline-block;

    .queres-img{
        display: inline-block;
        padding-right: 60px;
        position: relative;
        img {
        }
    }

    div.shadow {
        position: absolute;
        top: 63%;
        left: 4%;
        width: 65%;
        height: 28%;
        border-radius: 10px;
        display: block;
        background-color: transparent;
        box-shadow: $box-shadow;
        z-index: -1;

    }

    .pelota {
        position: absolute;
        right: -5px;
        bottom: 10px;
        display: inline-block;

        img {
            width: 65px;

        }

        &:before {
            content: " ";
            display: block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: rgba(0,0,0,0.5);
            box-shadow: $box-shadow;
            top: 50%;
            left: 50%;
            margin-top: -25px;
            margin-left: -25px;
            position: absolute;
            z-index: -1;
        }
    }
}




.fichaje-home {
    max-width: 500px;
    margin: auto;
    box-sizing: border-box;
    padding:0 20px;

    .youtube {
        padding-bottom: 55.4%;
        box-shadow: $box-shadow;
    }
}

    .youtube {
        position: relative;
        padding: 0;
        overflow: hidden;
        height: 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }




    .title-header {
        font-family: $black;
        font-size: 1.8em;
    }

    .text-desc {
        color: #fff;
        font-family: $black;
        font-size: 1.4em;
        line-height: 1.2;
        text-align: center;
        // padding: 0 15px;
        // margin-top: 40px;
        // text-shadow: 1px 5px 4px rgba(0,0,0,.8);
        text-shadow: $text-shadow;

        &.text-desc-sm {
            font-size: 0.9em;
        }

        &.text-desc-md {
            font-size: 1.6em;
        }

        &.text-desc-lg {
            font-size: 1.8em;
        }

        // a {
        //     color: #fff;
        //     text-decoration: none;
        // }
    }

    .btns-group {
        text-align: center;
        // margin-top: 30px;
    }

.btn {
    @extend .user-select-none;
    width: 120px;
    display: inline-block;
    padding: 8px;
    background: #fff;
    font-family: $franklin;
    text-decoration: none;
    font-size: 1.2em;
    border-radius: 8px;
    border: none;
    color: #000;
    text-align: center;
    margin: 0 5px;
    text-transform: lowercase;
    letter-spacing: 2px;
    cursor: pointer;
    // box-shadow: 5px 7px 5px rgba(0,0,0,.8);
    box-shadow: $box-shadow;
    transition: opacity .4s;

    &.btn-red {
        text-shadow: $text-shadow;
        color: #fff;
        @include linear-gradient(#EC810F 40%,#AB600F);
        // transition: all 0.5s ease;
        &:hover {
            @include linear-gradient(#EC810F 20%,#AB600F);
        }
    }

    &.btn-green {
        text-shadow: $text-shadow;
        color: #fff;
        @include linear-gradient(#00F600 40%,#008500);
        // transition: all 0.5s ease;
        &:hover {
            @include linear-gradient(#00F600 20%,#008500);
        }
    }

    &.btn-white {
        display: block;
        font-size: 1.1em;
        color: #000;
        min-width:120px;
        width:auto;
        @include linear-gradient(#ffffff 40%,#dadada);
        transition: all 0.5s ease;

        &:hover {
            @include linear-gradient(#ffffff 20%,#dadada);
        }
    }


    &.btn-w-auto {
        width: auto;
    }

    &.btn-border {
        border: 1px solid #000;
    }

    &.upper {
        text-transform: uppercase;
    }

    &.grdnt {
        display: block;
        margin: 0 auto 40px;
        width: 250px;
        letter-spacing: -0.2px;
        font-family: $black;
        text-transform: uppercase;
        padding: 15px 0;
        font-size: 1em;
    }
    // &.disable {
    //     opacity: .0;
    //     @include linear-gradient(#ddd 40%,#999);
    //     border-color: #999;
    //     color: #667;
    //     text-shadow: none;
    //     // box-shadow: none;
    // }
}

    div.form, div.el-input {
        text-align: center;
        // margin-top: 30px;
    }

    input[type="checkbox"]{
        width: 18px;
        height: 18px;
        position: relative;
        top: 5px;
    }


.el-form-item__content {
    line-height: 1;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;

    .el-input__prefix {
        display: none;
    }
}

.el-form {
    text-align: center;
}
.el-form-item, .el-select {
    width: 100%;
    max-width: 500px;
    // margin: 0 auto;
    // margin-right: auto;
    // margin-left: auto;
    margin: 0px auto 30px;
    display: inline-block;

    // display: block;

    label {
        color: #fff;
        margin-bottom: 5px;
        display: inline-block;
        font-size: 1.3em;
    }
}

.field-text, .el-input__inner {
    font-family: $sfnst;
    // box-shadow: 5px 7px 5px rgba(0,0,0,.8);
    box-shadow: $box-shadow;
    // width: 80%;
    border-radius: 10px;
    border: 1px solid #ccc;
    // padding: 10px 5px;
    letter-spacing: 1.3px;
    text-align: center;
    font-size: 16px;

    &.m-15 {
        margin: 15px 0;
    }

    &::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
    // &:-ms-input-placeholder, /* Internet Explorer 10-11 */
    // &::-ms-input-placeholder /* Microsoft Edge */
    {
        // font-size: 13px;
        letter-spacing: 0px;
        color: #999;
        text-transform: lowercase;
    }

}

.el-form-item__error {
    width: 100%;
    text-align: center;
}

.error, .el-form-item__error {
    // font-family: $sfnst;
    // font-family: $franklin;
    // font-family: arial;
    font-family: $black;
    color: #FF0;
    // text-transform: uppercase;
    text-transform: lowercase;
    line-height: 1.4em;
    letter-spacing: 1px;
    text-shadow: $text-shadow;
}

.box {
    // padding: 10px 10px;
    // font-family: $franklin;
    font-family: $sfnst;
    font-size: 1.2em;
    // background: rgba(0, 0, 0, 0.5);
    color: #fff;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;


    padding: 30px 20px;
    background: rgba(0,0,0,.5);
    border-radius: 10px;
    // font-size: 1.2em;
    color: #fff;


    p {
        line-height: 1.5em;
    }

    a {
        color: $orange;
        text-decoration: none;
    }
}

.fade-enter-active, .fade-leave-active {
    transition-property: opacity;
    transition-duration: .25s;
}

.fade-enter-active {
    transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
    opacity: 0
}


.el-carousel {
    max-width: 90%;
    margin: 0 auto;
}
/* Step fichaje foto  */
.picture {
    text-align: center;
    position: relative;
    padding-top: 85px;

    .user-picture {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -60px;

        img {
            width: 100px;
        }
    }

    .actions {

        @extend .user-select-none;
        background: rgba(0,0,0, 0.4);
        display: inline-block;
        padding: 5px 10px;
        border-radius: 5px;
        box-shadow: $box-shadow;

        .action {
            @extend .user-select-none;
            display: inline-block;
            color: #fff;
            text-transform: lowercase;
            font-family: $black;
            cursor: pointer;
            font-size: 1.2em;

            &.pick_picture {
                margin-left: 130px;
            }
        }
    }
}

.el-input-group__prepend {
    .el-form-item.is-error & {
        border-color: #f56c6c;
    }
    .el-form-item.is-success .el-input__inner & {
        border-color: #67c23a;
    }

    width: 90px;
    background-color: #fff;
    box-shadow: $box-shadow;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 5px;
    padding-right: 5px;
    .el-input {
        .el-input__inner {
            border-radius: none;
            height: 35px;
            box-shadow: none;
            padding: 0;
        }
        input {
            border: none;
        }
    }

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button{
    display: none;
}

.tel {
    max-width: 300px;
}

/*fechas*/
iframe.map {
    width: 100%;
    height: 280px;
}

.fechas-home {
    font-family: $sfnst;


    & > h1,
    & > h2,
    & > .anio {
        text-shadow: $text-shadow;
        letter-spacing: -0.2px;
        font-family: $black;
        letter-spacing: -0.1px;
        color: #fff;
        margin: 0 auto 20px;
        text-align: center;
    }

    & > h1 {
        font-size: 1.5em;
        text-transform: uppercase;
    }

    & > h2,
    & > .anio {
        font-size: 1.4em;
    }

    .ciudad, .dia {
        text-align: center;
    }

    .fecha {
        background: rgba(0,0,0, 0.5);
        border-radius: 10px;
        text-shadow: $text-shadow;
        font-size: 1.2em;
        color: #fff;
        padding: 30px 20px;

        .datos {
            margin-bottom: 30px;

            p.dato {
                text-transform: uppercase;
            }
        }

    }
        .confirmacion.pregunta {
            text-shadow: none;
            background: $orange;
            border-radius: 10px;
            padding: 30px 20px;
            text-align: center;
            color: #000;

            h3 {
                font-family: $black;
                text-shadow: none;
                text-align: center;
            }

            .btn {
                display: inline-block;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                font-family: $black;
                text-transform: uppercase;
                box-sizing: border-box;
                padding-top: 22px;
                margin: 10px;
            }

            .si {
                @include linear-gradient(#72BB3E,#477527);

                &:hover {
                    @include linear-gradient(#477527,#72BB3E);
                }
            }

            .no {
                @include linear-gradient(#EA0C0F,$red);

                &:hover {
                    @include linear-gradient($red,#EA0C0F);
                }
            }
        }



    .btn {
        text-shadow: none;
        display: block;
        margin: auto;
    }

    p {
        line-height: 1.3em;
    }
}

/*resultados*/
.resultados {
    font-family: "System Font";
    line-height: 1.5em;
}

.center {
    text-align: center;
    margin: 20px auto;
}

h1.text-desc {
    color:#000;
    text-transform:uppercase;
    text-shadow:none;
}

h2.text-desc {
    font-size: 1em;
}

.black-panel {
    background: #333333;
    border-radius: 10px;
    font-size: 1.2em;
    color: #fff;
    padding: 30px 20px;

    text-align: center;
    box-shadow: $box-shadow;

    .btn {
      display: inline-block;
    }
}

.orange-panel {
    background: #EC810F;
    border-radius: 10px;
    font-size: 1.2em;
    color: #fff;
    padding: 30px 20px;

    p {
        font-size: 0.9em;
        color:#000;
    }
}

.btn.btn-red.qualification {
    font-family: "System Font";
    color: #000;
    font-size: 1.4em;
    cursor: none;
    pointer-events: none;
    text-shadow: none;
    padding: 10px;
}

.average span {
    display: block;
    float: left;
    padding: 10px 0 0 20px;
    text-shadow: -4px 4px 4px rgba(0,0,0,.8);
    font-size: 0.8em;
    font-weight: bold;
}

.average div {
    display: block;
    float: right;
    border-radius: 50%;
    height: 30px;
    width: 40px;
    background: green;
    color: #fff;
    box-shadow: -8px 8px 20px rgba(0,0,0,.7);
    padding-top: 10px;
    font-size: 0.9em;
    text-align: center;
}

.average div:only-child {
    display: block;
    margin: auto;
    height: 40px;
    width: 60px;
    padding-top: 20px;
    font-size: 1em;
    float: none;
}


.copyright {
    font-family: "System Font";
    line-height: 1.2em;
}
