
.user-picture {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    background-color: rgba(150, 150, 150, .6);
	background-size: cover;
	background-position: center;

	header & {
    	background-color: transparent;
	}
}