@import "mixins";

// paleta colores
$black: #000000;
$white: #ffffff;
$blue: #0080b0;
$red: #B6172C;
$orange: #FF931E;

/* fonts */
$black: 'Arial-Black'; 
$sfnst: 'System Font';
$franklin: 'Franklin Gothic Book';


$text-shadow: -4px 4px 4px rgba(0,0,0,.8);
$box-shadow: -8px 8px 20px rgba(0,0,0,.7);
