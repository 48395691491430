@import "../sass/variables";

$w: 50px;
$h: 70px;
.player-shirt{
	width: $w;
	height: $h;
	// top: 50%;
	// left: 100%;
	display: inline-block;
	position: relative;
	text-align: center;
	// background: url(../img/bg/shirt_bg_orange.png) 100% 0 no-repeat;
	background: url(../img/bg/remera_naranja_sombra.png) 0 0 no-repeat;
	background-size: cover;
	background-position-x: center; 
	transform-origin: 50% 50%;
	transition: transform .3s;
	transition-timing-function: "ease-in-out";
	-webkit-transition-timing-function: "ease-in-out";
	
	&.shirt-1 {
		background-image: url(../img/bg/remera_gris_sombra.png);
	}

	.soccer-field-wrapper &{
		cursor: pointer;
		position: absolute;
		margin-left: -$w/2;
		margin-top: -$h/2;
	}

	&.active {
		background-image: url(../img/bg/remera_celeste_sombra.png);
		transform: scale(1.3);
	}

	.shirt-number {
		font-family: $black;
		font-size: 20px;
		margin-top: 12px;
		margin-left: -8%;
	}

	img {
		width: 30%;
		position: absolute;
		bottom: 24%;
		left: 30%;
		display: inline-block;
	}

}