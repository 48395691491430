.user-description-component {
	.image-content {
		// margin-left: 20px;
		transform: translate(20px, 0);
	}
	
	.player-shirt-wrapper {
		display: inline-block;
		position: relative;
		transform: scale(1.8);
		transform-origin: 100% 100%;
		top: -50px;
		margin-right: 10px;
			// position: static;
		.player-shirt {
			// cursor: pointer;
		}
	}
	
	.photo {
		text-align: center;

		.user-picture {
			border-color: #fff;
			// cursor: pointer;
		}
	}
}