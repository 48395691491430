@import "variables";
@import "helpers";

.links {
    text-align: center;
    // padding-top: 40px;

    img {
        height: 65px;
        margin: 40px auto;
        display: block;
    }

}
