
.selected-position-str {
	height: 2.5em;
	line-height: 1.5em;
	opacity: 0;
	transition: opacity 0.5s;
	&.active {
		opacity: 1;
	}

}