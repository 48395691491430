@import "variables";
// @import "../../node_modules/csshake/scss/tools";

// .shake      { @include do-shake(); }
// .shake-chunk      { @include do-shake('shake-chunk', 30, 30, 30, 4s, $chunk: 20%); }
// .shake-slow       { @include do-shake('shake-slow', 20, 20, 7, 5s, $chunk: 20%, $delay: 10s); }


/* text-transform */
.upper { text-transform: uppercase; }
.lower { text-transform: lowercase; }
.justify { text-align: justify;}
.center { text-align: center;margin:20px auto;}
.decoration-none { text-decoration: none; }
.bolder { font-family: $black; }

.shadow { box-shadow: $box-shadow; }
.text-shadow { text-shadow: $text-shadow; }
.pointer { cursor: pointer; }

/* margin-top */
.mt-1 { margin-top: 10px; }
.mt-2 { margin-top: 20px; }
.mt-3 { margin-top: 30px; }
.mt-4 { margin-top: 40px; }
.mt-5 { margin-top: 50px; }
.mt-6 { margin-top: 60px; }
.mt-7 { margin-top: 70px; }
.mt-8 { margin-top: 80px; }
.mt-9 { margin-top: 90px; }

/* margin-bottom */
.mb-1 { margin-bottom: 10px; }
.mb-2 { margin-bottom: 20px; }
.mb-3 { margin-bottom: 30px; }
.mb-4 { margin-bottom: 40px; }
.mb-5 { margin-bottom: 50px; }

.hidden { display: none; }

.user-select-none{
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
}

.round {
    border-radius: 10px;
    min-height:200px;
}

.emoji {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url('../img/risa_emoji.png') 0 0 no-repeat;
  background-size: cover;
}
