@import "variables";
@import "helpers";

.tutoriales {
    h1 {
        font-size: 2em;
        text-align: center;
    }

    &.show-tuto {
        .tutoslist, h1{
            opacity: 0;
        }
        .tuto-description{
            pointer-events: all;
            opacity: 1;
        }

    }


    .tutoslist {
        // padding: 20px 35px;
        transition: all .5s;
        .tutorial {
            & > * {
                width: 47.5%;
                float: left;
                box-sizing: border-box;

                &:hover {
                    cursor: pointer;
                }
            }

            .box {
                position: relative;
                padding: 0;
                overflow: hidden;
                padding-bottom: 26.4%;
                height: 0;
                margin-left: 2.5%;
                text-align: center;

                h2 {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%) translateX(0);
                    padding: 0 10px;
                    line-height: 1.3em;
                    min-width: 90%;
                }
            }

            .youtube {
                margin-right: 2.5%;
                padding-bottom: 26.4%;

                .cover {
                    &.t1 {
                        background: url(../img/tutos/01.png) no-repeat;
                    }

                    &.t2 {
                        background: url(../img/tutos/02.png) no-repeat;
                    }

                    &.t3 {
                        background: url(../img/tutos/03.png) no-repeat;
                    }

                    &.t4 {
                        background: url(../img/tutos/04.png) no-repeat;
                    }

                    &.t5 {
                        background: url(../img/tutos/05.png) no-repeat;
                    }

                    &.t6 {
                        background: url(../img/tutos/06.png) no-repeat;
                    }

                    &.t7 {
                        background: url(../img/tutos/07.png) no-repeat;
                    }

                    background-size: 100% !important;
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    width: 100%;
                    padding-bottom: 56.25%;
                    display: none;
                }

                &:after {
                    content: '';
                    background: url(../img/tutos/play.png) no-repeat;
                    background-size: 100%;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    z-index: 4;
                    display: none;
                }
            }
        }
    }

    .tuto-description {

        padding: 35px;
        position: fixed;
        top: 95px;
        left: 0;
        transition: all .5s;
        pointer-events: none;
        opacity: 0;
        
        .box {
            padding: 30px 30px 15px;
            max-width: 100%;
            padding-bottom: 60px;
            position: relative;
            min-height: 350px;

            p {
                line-height: 1.3em;
                margin-bottom: 10px;
            }

            .cerrar-text {
                color: #fff;
                position: absolute;
                left: 50%;
                bottom: 30px;
                transform: translateY(0) translateX(-50%);
            }


            @media screen and (max-width: 370px) { // NOTA
                min-height: auto;
                max-height: 300px;
                overflow: auto;
                
                .cerrar-text {
                    position: static;
                    text-align: center;
                    transform: none;
                    display: block;       
                }
            }
        }

        h2 {
            text-align: center;
        }
    }
}


