@import "variables";

.legales {
    max-width: 400px;
    width: 85%;
    margin: 30px auto 0;
    font-family: $sfnst;

    h1 {
        text-align: center;
        font-weight: bold;
        font-family: $black;
        line-height: 1.3em;
        font-size: 1.2em;
    }

    h2 {
        clear: both;
        text-align: center;
        padding-top:20px;
    }

    p {
        line-height: 1.5em;
        font-size: 1em;
    }
}
