@import "variables";

.user-profile {
	max-width: 90%;
	margin: 0 auto;
	
	&, p{
		font-family: $sfnst;
	}

	.position-str {
		font-size: 18px;

		span {
			display: inline-block;
			width: 35px;
			height: 26px;
			padding-top: 9px;
			border-radius: 50%;
			background: $orange;
		}
	}
}

.rows-wrapper {
	
	// font-family: $sfnst;

}
