@import "variables";
@import "helpers";

.fichaje {
    .circulos {
        text-align: center;

        .genero {
            width: 40%;
            display: inline-block;
            text-align: center;
            margin: 0 2%;

            &:hover, &.active {
                transition: .2s ease;

                span {
                    background: rgba(255,147,30,0.5);
                }
            }

            &.deactivate {
                // opacity: 0.3,
            }

            span {
                display: inline-block;
                position: relative;
                width: 136px;
                height: 136px;
                border-radius: 50%;
                background: rgba(0,0,0,0.5);
            }

            img {
                transition: .3s ease-in-out;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
                height: 113px;
            }

            p {
                font-size: 1.1em;
                letter-spacing: 0.5px;
            }
        }
    }
}
