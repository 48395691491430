@import "../sass/variables";


.row-description {
	font-size: 18px;
	text-align: left;
	padding-top: 10px;

	.label, .val {

	}

	.label {
		color: #667;
	}

	[class*=" el-icon-"], [class^=el-icon-] {
	    color: #FF931E;
	    border: solid 1px;
	    padding: 5px;
	    border-radius: 50%;
	    background: #fff;
	    box-shadow: $box-shadow;
	    margin-right: 5px;
	    /* background: #ccc; */	
	}
}