/*@font-face {
    font-family: 'Arial-Black';
    src: url('fonts/Arial-Black.woff2') format('woff2'),
        url('fonts/Arial-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
*/


@font-face {
    font-family: 'System Font';
    src: url('fonts/SFNSText.eot');
    src: url('fonts/SFNSText.eot?#iefix') format('embedded-opentype'),
        url('fonts/SFNSText.woff2') format('woff2'),
        url('fonts/SFNSText.woff') format('woff'),
        url('fonts/SFNSText.svg#.SFNSText') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Arial-Black';
    src: url('fonts/Arial-Black.eot');
    src: url('fonts/Arial-Black.eot?#iefix') format('embedded-opentype'),
        url('fonts/Arial-Black.woff2') format('woff2'),
        url('fonts/Arial-Black.woff') format('woff'),
        url('fonts/Arial-Black.svg#Arial-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Franklin Gothic Book';
    src: url('fonts/FranklinGothic-Book.eot');
    src: url('fonts/FranklinGothic-Book.eot?#iefix') format('embedded-opentype'),
        url('fonts/FranklinGothic-Book.woff2') format('woff2'),
        url('fonts/FranklinGothic-Book.woff') format('woff'),
        url('fonts/FranklinGothic-Book.svg#FranklinGothic-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}

