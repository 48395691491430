@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

// .infinite {
// 	animation-iteration-count:infinite;
// }

.pulse {
  animation: pulse 2s infinite;
  -webkit-animation: pulse 2.5s infinite;
}