@import "variables";

.card {
	$card_radius: 10px;
	// background: #000 center / cover no-repeat;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	position: relative;
	margin: 30px auto 0;

	// width: 100vh;
	// height: 130vh;
	max-width: 400px;
	// width: 85%;
	height: 400px;
	box-shadow:$box-shadow;
	border-radius: $card_radius;
    cursor: pointer;

	.header-card {
		padding-bottom: 20px;
		background: linear-gradient(to bottom, rgba(0,0,0,.8), rgba(0,0,0,0));
		border-radius: $card_radius $card_radius 0 0;

		h3 {
			font-family: $black;
			color: #fff;
			// width: 90%;
			text-align: center;
			// position: absolute;
			// top: 20px;
			// left: 0;
			font-size: 2em;
			padding: 20px 10px;
			text-shadow:$text-shadow;
		}
	}


	.card-text {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		color: #fff;
		padding-top: 30px;
		// background: rgba(0, 0, 0, .3);
		background: linear-gradient(to top, rgba(0,0,0,.7), rgba(0,0,0,0));
		border-radius: 0 0 $card_radius $card_radius;
		p {
			padding: 10px 10px 0;
			margin-bottom: 10px;
			font-family: $sfnst;
			line-height: 1.4em;
			font-size: 1.3em;
			text-shadow: $text-shadow;

			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;

		}
	}
}


/*landing desktop*/
@media screen and (min-width: 1024px) {
    .main-wrapper.page-landing {
        max-width: none;
    }
    .landing {
        // position: absolute;
        // left: 0;
        // width: 100vw;
        max-width: 1350px;
        margin: auto;
        text-align: center;

        .card {
            position: relative;
            width: 400px;
            display: inline-block;
            vertical-align: top;
            margin: 30px 20px;
            height: 460px;
            background-color: rgba(0,0,0,0.7);
            overflow: hidden;

            &:nth-child(1) {
                width: 840px;
                max-width: none;

                .youtube {
                    width: 840px !important;
                }
            }

            .youtube {
                height: 300px !important;
                width: 400px !important;
                position: absolute !important;
                top: 50% !important;
                left: 50% !important;
                transform: translate(-50%, -50%) !important;
            }

            .header-card {
                position: relative;
                z-index: 3;
            }
        }
    }
}


