.user-interface {

	header {
		
		// padding-top:7px;
		// position: relative;
		// padding-bottom: 20px;
		// margin-bottom: 15px;

		.line-header {
			opacity: 0;
			transition: all .5s;
			pointer-events: none;
			display: inline-block;
			width: 90%;
			height: 15px;
			// background: url(../img/bg/raya_midd.png);
			background: url(../img/bg/raya2.png);
			// background-repeat-y: no-repeat;
			background-position: 0 -3px;
			margin-left: 5%;
			position: absolute;
			bottom: -5px;
			left: 0;
			// border-bottom: solid 2px #000;
			border: none;
			
			body.field_bg & {
				opacity: 1;
				// content: " ";
				// border-color: #fff;
			}

			&:after, &:before {
				content: " ";
				display: inline-block;
				height: 15px;
				width: 20px;
				position: absolute;
				top: 0;
				left: -10px;
				// background: url(../img/bg/raya_end.png) 1px -6px no-repeat;
				background: url(../img/bg/raya2.png) 0px -26px no-repeat;
			}
			
			&:after {
				
				// background: url(../img/bg/raya_end.png) -20px -35px;
				background: url(../img/bg/raya2.png) 0px -45px;
				left: auto;
				right: -10px;
			}
		}

		.logo-queres-jugar .queres-img {
			padding-right: 0;

			.pelota {
				display: none;
			}

			img.queres-jugar-txt{
				width: 110px;
			}

		}

		.user-picture {
		    position: absolute;
		    top: 15px;
		    right: 5%;
    		border: 2px solid #000;
    		cursor: pointer;

			
			&.incomplete_perfil {
				animation: move 10s infinite ease-in-out;
				// &:after {
	   //          	content: "";
	   //          	display: inline-block;
	   //          	width: 10px;
	   //          	height: 10px;
	   //          	background: red;
	   //  			border: 1px solid #fff;

	   //  			position: absolute;
	   //  			bottom: 0;
	   //  			right: 20%;
	   //  			border-radius: 50%;
	   //  			// z-index: 1;

	   //      	}
			}


			body.field_bg & {
				border-color: #fff;
			}
		}

	}


	#hamburger {
		display: block;
		width: 42px;
		// height: 50px;
		position: absolute;
		top: 20px;
		left: 5%;
		transition: stroke 0.5s;
		// background: url(../img/bg/hamburguesita_futbolera_bg.png) no-repeat ;
		background-size: cover;
		cursor: pointer;
		
		// line {

		// 	stroke: #000;
			
		// 	/* Si el body tiene la clase field_bg, las lineas van blancas */
		// 	body.field_bg & {
		// 		stroke: #fff;
		// 	}

		// }

		div {
		    width: 100%;
		    height: 5px;
		    background-color: black;
		    margin: 7px 0;
		    border-radius: 5px;
		    box-shadow: $box-shadow;
		}

		body.field_bg & {
			div {
		    	background-color: #fff;
			}
			// background-image: url(../img/bg/hamburguesita_futbolera_blanca_bg.png);
		}
		
	}
}

#hamburger {
	display: none;
}



@keyframes move {
  0% {
    // transform: scale(1);
    border-color: #fff;
	// border: 2px solid #000;
  }

  /* Finish changes by here */
  20% {
    // transform: scale(1.1);
    border-color: #f00;
  }

  /* Between 20% and 100%, nothing changes */
  30%, 100% {
    // transform: scale(1);
    border-color: #fff;
  }
}