
.soccer-field-wrapper {

    width: 100%;

    .soccer-field {
    	position: relative;
    	margin: 0 auto;
    	border: 0px solid #ccc; 
    	background: url(../img/bg/lineas_campo_de_juego.png) no-repeat;
    	background-size: cover;
    }

}
